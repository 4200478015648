<template>
<div>
    <NavTools />
        <div style="background-color:#fff">
            <div class='container' style="max-width: 800px;">
                <div class="row">
                    <div class='col-md-12'>
                        <div class="row">
                            <div class="col-sm-4 col-sm-offset-4">
                                <img :src="staticaddr + '/s/img/_shared/recruit.png'" class="img-responsive">
                            </div>
                        </div>

                        <p class="lead">After a student sends interview materials to one institution, a proprietary algorithm suggests other schools to consider. If a program receives interview materials via this “suggestion engine”, a magnifying glass icon appears with the interview. Since the student sent interview materials after receiving a suggestion, it may be that the program should see this as a “lead” and take a more proactive stance towards recruiting the student.</p>

                        <p class="lead">Programs may, if they like, send a video message to the student directly from the interview player under the student’s video reciprocate the expression of interest. As part of the InitialView interview process, students download an app to their phone. This video messaging feature may be one of the best ways to reach students directly.</p>

                        <div class="well">
                            <img :src="staticaddr + '/ivgcs/img/amelia-recruit.png'" class="img-responsive">
                        </div>

                        <p class="lead">Given the quality of students who participate in our process, our hope is that this will become another source of talent for your institution. There is no charge to students or institutions for this feature. To optimize this “suggestion engine”, institutions that use InitialView can provide us a list of “peer schools”. If your institution hasn’t provided us with a peer school list yet, you can send us an email at amelia@initialview.com.</p>

                    </div>
                </div>
            </div>
        </div>
</div>
</template>

<script>

import Vue from 'vue';
import VueResource from 'vue-resource';
Vue.use(VueResource);

import NavTools from "@/components/NavTools.vue";

require("@/utils/LoginRequired.js");

export default {

    props: [
        'staticaddr',
        'serveraddr',
        'ameliaaddr',
        'lang',
        'lang_label',
    ],

    components: {
        NavTools
    },
}

</script>

<style lang="scss">

</style>